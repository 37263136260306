import React from "react";

import styles from "./styles/footer.module.css";

function footer() {
    return (
        <footer className={styles.js__blogfooter}>
          <span className={styles.js__blogfooter_copyright}>© {new Date().getFullYear()}, Jacob Scearcy</span>
        </footer>
    );
}

export default footer;