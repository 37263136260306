import React from "react";
import { rhythm } from "../utils/typography";

import Header from "./header";
import Footer from "./footer";
import styles from "./styles/layout.module.css";

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <section>
      <section
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          position: `relative`,
          minHeight: `100vh`,
        }}
      >
        <Header />
        <main className={styles.js__blogmain}>{children}</main>
        </section>
        <Footer />
      </section>
    )
  }
}

export default Layout
