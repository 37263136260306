import React, { useState, useEffect } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Image from "gatsby-image";

import headerStyles from "./styles/header.module.css";
import TwitterIcon from "../../content/assets/Twitter_Logo_Blue.svg";
import GithubIcon from "../../content/assets/Github_Logo.svg";
import * as windowUtils from "../utils/windowUtils";
import * as genericUtils from "../utils/genericUtils";

function Header() {
  const [currentSaying, updateCurrentSaying] = useState(genericUtils.sayingList[2]);
  const [screenWidth, updateScreenWidth] = useState(windowUtils.getInnerWidth());

  const screenResized = () => {
    updateScreenWidth(windowUtils.getInnerWidth());
  };

  useEffect(() => {
    const randomSayingIdx = Math.floor(Math.random() * genericUtils.sayingList.length);
    updateCurrentSaying(genericUtils.sayingList[randomSayingIdx]);
    windowUtils.addEventListener('resize', screenResized);
    return () => {
      windowUtils.removeEventListener('resize', screenResized);
    };
  }, []);

  return (
    <StaticQuery
      query={headerQuery}
      render={data => {
        const { author, social, title } = data.site.siteMetadata;
        return (
          <header className={headerStyles.js__blogheader}>
            <div className={headerStyles.js__blogheader_social}>
              <a href={`https://twitter.com/${social.twitter}`} rel="noopener noreferrer" target="_blank">
                <TwitterIcon />
              </a>
              <a href={`https://github.com/${social.github}`} rel="noopener noreferrer" target="_blank">
                <GithubIcon />
              </a>
            </div>
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              className={headerStyles.js__blogheader_img}
              style={{
                display: screenWidth >= 850 ? 'inline' : 'none',
                position: 'fixed',
                top: '50px',
                left: '25px',
              }}
            />
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                textShadow: 'none',
              }}
              to={'/'}>
              <h1 className={headerStyles.js__blogheader_title}>{title}</h1>
            </Link>
            <div>
              <h2 className={headerStyles.js__blogheader_saying}>{currentSaying}</h2>
            </div>
          </header>
        )
      }
      }
    />
  );
}

const headerQuery = graphql`
  query HeaderQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        author
        siteUrl
        social {
          twitter
          github
        }
      }
    }
  }
`

export default Header;
