import Typography from "typography"
import Lincoln from "typography-theme-lincoln";

Lincoln.overrideThemeStyles = () => ({
  "h3.js__blogpost_header > a, li > a, p > a": {
    color: "rgb(46, 125, 50)",
    "background-image": "linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1px, #2e7d32 1px, #2e7d32 2px, rgba(0, 0, 0, 0) 2px)",
  },
  "h3.js__blogpost_header > a:hover, li > a:hover, p > a:hover, a": {
    "background-image": "none",
  },
});

const typography = new Typography(Lincoln);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
