function mapWindow(fn, defaultVal) {
    if (typeof window !== 'undefined') {
        return fn(window);
    } else {
        return defaultVal;
    }
}

function getInnerWidth() {
    return mapWindow((window) => window.innerWidth, 0);
}

function addEventListener(event, cb) {
    return mapWindow((window) => window.addEventListener(event, cb));
}

function removeEventListener(event, cb) {
    return mapWindow((window) => window.removeEventListener(event, cb));
}

function requestAnimationFrame(cb) {
    return mapWindow((window) => window.requestAnimationFrame(cb));
}

module.exports = {
    addEventListener,
    getInnerWidth,
    removeEventListener,
    requestAnimationFrame,
}